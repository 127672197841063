export const operationAdsHome = `
  query GetHomeUserFeatures($userId: Int!, $limit: Int = 4) {
    users: advertising_feature_cards(where: {type: {_eq: "user"}, location: {_eq: "home"}}, limit: $limit) {
      user {
        name
        username
        isWorker
        isVerified
        isBetaUser
        isActive
        isPartner
        isSponsored
        id
        country {
          name
          id
        }
        bannerImage
        about
        aboutRich
        profileImage
        lookingForJob
        profileTypes(where: {isActive: {_eq: true}}) {
          profileType {
            name
            slug
            id
            category
            color
            icon
          }
          isActive
          order
        }
        userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
          total: aggregate {
            count(columns: userId)
          }
        }
      }
    }
  }

  query GetHomeEventFeatures($limit: Int = 4) {
    events: advertising_feature_cards(where: {type: {_eq: "event"}, location: {_eq: "home"}}, limit: $limit) {
      event {
        date
        image
        imageOg
        id
        game {
          banner
          name
          slug
          thumbnail
        }
        eventTypeId
        eventCategoryId
        eventTagId
        gameId
        link
        isActive
        profileType {
          name
          slug
          color
          category
          icon
        }
        participationTypeId
        title
        slug
        eventType {
          active
          name
        }
        description
        isParent
        parentEventId
        userId
        owner {
          name
          username
          profileImage
        }
        isFeature
        eventTag {
          name
          id
        }
        eventCategory {
          id
          name
        }
      }
    }
  }

  query GetUserByProfileTypeHome($userId: Int!, $limit: Int = 4, $offset: Int = 0) {
    profileTypes: profile_types(order_by: {userCount: desc, id: desc}, where: {user_profile_types_aggregate: {count: {predicate: {_gt: 0}, arguments: id}}, user_profile_types: {isActive: {_eq: true}}}, limit: $limit, offset: $offset) {
      slug
      name
      isActive
      icon
      color
      category
      userCount
      usersProfileTypes: user_profile_types(order_by: {user: {userFollowers_aggregate: {count: desc}, isVerified: desc, isBetaUser: desc}}, where: {isActive: {_eq: true}, profileType: {}}, limit: 3) {
        user {
          name
          username
          isWorker
          isVerified
          isBetaUser
          isActive
          isPartner
          isSponsored
          id
          country {
            name
            id
          }
          bannerImage
          about
          aboutRich
          profileImage
          lookingForJob
          profileTypes(where: {isActive: {_eq: true}}) {
            profileType {
              name
              slug
              id
              category
              color
              icon
            }
            isActive
            order
          }
          userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
            total: aggregate {
              count(columns: userId)
            }
          }
        }
      }
      featureUsersProfileTypes: feature_user_profile_types(where: {type: {_eq: "user"}, location: {_eq: "community"}}) {
        user {
          name
          username
          isWorker
          isVerified
          isBetaUser
          isActive
          isPartner
          isSponsored
          id
          country {
            name
            id
          }
          bannerImage
          about
          aboutRich
          profileImage
          lookingForJob
          profileTypes(where: {isActive: {_eq: true}}) {
            profileType {
              name
              slug
              id
              category
              color
              icon
            }
            isActive
            order
          }
          userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
            total: aggregate {
              count(columns: userId)
            }
          }
        }
      }
    }
    pagination: profile_types_aggregate(where: {user_profile_types_aggregate: {count: {predicate: {_gt: 0}, arguments: userId}}, user_profile_types: {isActive: {_eq: true}}}) {
      data: aggregate {
        total: count(columns: id)
      }
    }
  }  
`;