import { Game, InfinitePaginationResponse, LoginProvider, ProfileType, ProfileTypesPagination, Social, TemporaryUser, UsersProfileType } from "@/domain/general.interface";
import { apgGraphQL } from "./apgApi"
import { operationGetGeneral, operationGetTemporary, operationGetUser, operationProfileTypes } from "./graph-queries";
import { convertCountriesToOptions, convertGamesToOptions, convertProfileTypesToOptions, mapUsersToCards } from "@/lib/utils/generals";
import { SelectOption } from "@apg.gg/core/lib/Select";
import { generalOperations, homeOperations } from "./graph-general-queries";
import { CardProfileProps } from "@apg.gg/core/lib/CardProfile";
import { operationAdsHome } from "./advertising/queries";
import _ from "lodash";

export interface RequestError {
  response: {
    status: number;
  },
  title: string,
  message: string,
  code?: string | number
}

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

const getUser = async (providerId: number, email: string, password: string): Promise<any> => {
  try {
    const { data } = await apgGraphQL(
      operationGetUser,
      'GetUserLogin',
      {
        "where": {
          "provider_id": { "_eq": providerId },
          "email": { "_eq": email },
          "password": { "_eq": password },
        }
      }
    )

    return data.logins[0];
  } catch (error) {
    throw getError(error as RequestError)
  }
}

const getCountries = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GeneralQuery'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const countryOptions = convertCountriesToOptions(data.countries);

  return countryOptions;
}

const getSocials = async (): Promise<Social[]> => {
  try {
    const { data } = await apgGraphQL(
      operationGetGeneral,
      'SocialQuery'
    )

    return data.socials;
  } catch (error) {
    throw getError(error as RequestError)
  }
}

const getTemporaryUser = async (uuid: string): Promise<TemporaryUser> => {
  const { data, errors } = await apgGraphQL(
    operationGetTemporary,
    'GetTemporaryUser',
    {
      "id": uuid
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.temporaryUser;
}

const deleteTemporaryUser = async (uuid: string): Promise<TemporaryUser> => {
  try {
    const { data, errors } = await apgGraphQL(
      operationGetTemporary,
      'DeleteTemporaryUser',
      {
        "id": uuid
      }
    )

    return data.deletedUser;
  } catch (error) {
    throw getError(error as RequestError)
  }
}

const getLoginProviders = async (): Promise<LoginProvider[]> => {
  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GetLoginProviders'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.loginProviders;
}

const getProfileTypes = async (): Promise<ProfileType[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileTypes,
    'GetProfileTypes'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.profileTypes;
}

const getProfileTypeOptions = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileTypes,
    'GetProfileTypes'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertProfileTypesToOptions(data.profileTypes);
}

const getGames = async ({ sort = 'users-quantity', limit }: any): Promise<Game[]> => {
  let sortObject;

  switch (sort) {
    case 'name-asc':
      sortObject = { name: 'asc' };
      break;
    case 'name-desc':
      sortObject = { name: 'desc' };
      break;
    default:
      sortObject = { users_aggregate: { count: 'desc' }};
      break;
  }

  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GetGames',
    {
      "order_by": sortObject,
      ...(limit ? { "limit": limit } : {})
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.games;
}

const getGamesLikeOptions = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GetGames'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertGamesToOptions(data.games);
}

const getGameBySlug = async ({ slug }: { slug: string }): Promise<Game> => {
  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GetGameBySlug',
    {
      "slug": slug
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.games[0];
}

const getUsersByGameSlug = async <T>({ 
  slug, 
  profileTypes, 
  clasifications, 
  limit = 12, 
  page = 1,
  userLoggedId
}: {
  slug: string, 
  profileTypes: string[], 
  clasifications: string[], 
  limit?: number, 
  page?: number,
  userLoggedId?: number
}): Promise<InfinitePaginationResponse<T>> => {
  const offset = (page - 1) * limit;

  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GetUsersByGameSlug',
    {
      "slug": { "_eq": slug },
      "profileType": profileTypes.length > 0 ? { "slug": { "_in": profileTypes }} : {}, 
      "clasification": clasifications.length > 0 ? { "slug": { "_in": clasifications }} : {},
      "limit": limit,
      "offset": offset,
      "userId": userLoggedId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.usersByGameSlug,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
  };
}

const getGameRoles = async (gameId: number): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GetGameRoles',
    {
      "gameId": gameId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertGamesToOptions(data.gameRoles);
}

const getClasifications = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationGetGeneral,
    'GetClasifications'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertGamesToOptions(data.clasifications);
}

const getUsersByProfileType = async ({
  profileTypes, 
  limit = 12, 
  page = 1,
  userLoggedId = 0
}: {
  profileTypes: string[], 
  limit?: number, 
  page?: number,
  userLoggedId?: number
}): Promise<InfinitePaginationResponse<CardProfileProps>> => {
  const whereObject = 
    profileTypes.length > 0 ? 
      { 
        "where": { 
          "profileTypes": { 
            "profileType": { "slug": { "_in": profileTypes }}, 
            "isActive": { "_eq": true}
          }
        }
      } : { "where": {} }
      
  const { data, errors } = await apgGraphQL(
    generalOperations,
    'GetUserByProfileType',
    {
      ...whereObject,
      "limit": limit,
      "offset": (page - 1) * limit,
      "userId": userLoggedId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: mapUsersToCards(data.users, profileTypes),
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getUsersByProfileTypeHome = async ({
  limit = 8, 
  page = 1,
  userLoggedId = 0
}: {
  limit?: number, 
  page?: number,
  userLoggedId?: number
}): Promise<InfinitePaginationResponse<any>> => {
  const { data, errors } = await apgGraphQL(
    operationAdsHome,
    'GetUserByProfileTypeHome',
    {
      "limit": limit,
      "offset": (page - 1) * limit,
      "userId": userLoggedId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.profileTypes.map((item: any) => {
      let usersProfileType = item.usersProfileTypes.map((usersProfileType: UsersProfileType) => usersProfileType.user);
      const featureUsersProfileTypes = item.featureUsersProfileTypes.map((usersProfileType: UsersProfileType) => usersProfileType.user);

      while (featureUsersProfileTypes.length < 3) {
        const difference = _.differenceWith(usersProfileType, featureUsersProfileTypes, _.isEqual);
        
        if (difference.length > 0) {
          featureUsersProfileTypes.push(difference[0]);
          usersProfileType = usersProfileType.filter((user: UsersProfileType) => !_.isEqual(user, difference[0]));
        } else {
          break;
        }
      }

      return {
        ...item,
        icon: item.icon,
        count: item.userCount,
        users: mapUsersToCards(featureUsersProfileTypes, [])
      }
    }),
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getFeatureUsers = async ({
  limit = 5, 
  userLoggedId = 0
}: {
  limit?: number, 
  userLoggedId?: number
}): Promise<any[]> => {
  const { data, errors } = await apgGraphQL(
    operationAdsHome,
    'GetHomeUserFeatures',
    {
      "limit": limit,
      "userId": userLoggedId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return mapUsersToCards(data.users.map((usersProfileType: UsersProfileType) => usersProfileType.user), []);
}

const getUsersMarquee = async ({
  limit = 16, 
  userLoggedId = 0
}: {
  limit?: number, 
  userLoggedId?: number
}): Promise<any[]> => {
  const { data, errors } = await apgGraphQL(
    homeOperations,
    'GetUsersMarquee',
    {
      "limit": limit,
      "userId": userLoggedId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return mapUsersToCards(data.users, []);
}

export type InsertFilePayload = {
  file: string,
  userId: number,
  name: string,
  token: string,
  type: string
}


const insertFile = async ({
  file,
  userId,
  name,
  type,
  token
}: InsertFilePayload): Promise<{
  imageUrl: string,
  imageOg: string,
  message: string,
  success: boolean
}> => {
  const { data, errors } = await apgGraphQL(
    generalOperations,
    'InsertFile',
    {
      "file": file,
      "id": userId,
      "name": name,
      "type": type
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  if (data.insertFile.success === false) {
    throw getError({
      title: 'Error al subir la imagen',
      message: data.insertFile.message,
      response: { status: 500 }
    })
  }

  return {
    imageUrl: data.insertFile.imageUrl,
    imageOg: data.insertFile.imageOg,
    message: '',
    success: data.insertFile.success
  }
}

export const GeneralEndpoints = {
  getUser,
  getCountries,
  getSocials,
  getTemporaryUser,
  deleteTemporaryUser,
  getLoginProviders,
  getProfileTypes,
  getProfileTypeOptions,
  getGames,
  getGamesLikeOptions,
  getGameBySlug,
  getUsersByGameSlug,
  getGameRoles,
  getClasifications,
  getUsersByProfileType,
  getUsersByProfileTypeHome,
  getFeatureUsers,
  getUsersMarquee,
  insertFile
}
